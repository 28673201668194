<template>
    <!-- 新增案件視窗 -->
    <vs-popup :title="$t('crm.addCase')" style="z-index: 51001" :active.sync="popupActive" @close="close">
        <vs-row class="grid gap-8 cd-appt-input">
            <!-- 選民眾 -->
            <vs-col class="flex flex-wrap gap-8 w-full">
                <div class="flex-grow">
                    <div class="mb-1 flex justify-between items-end">
                        <span><span class="text-danger">* </span>{{ $t('components.apptBtn.patient') }}</span>
                        <span class="cd-p-small">{{ $t('components.apptBtn.patientTip') }}</span>
                    </div>
                    <div>
                        <el-select
                            class="w-full"
                            popper-class="cd-appt-popper"
                            v-model="patient.pid"
                            id="select"
                            ref="select"
                            filterable
                            remote
                            clearable
                            placeholder=""
                            :remote-method="searchpartnermember"
                            :loading="patient.loadingmember"
                            @clear="removesearchpartnermember"
                            @hook:mounted="cancalReadOnly"
                            @visible-change="cancalReadOnly"
                            @focus="cancalReadOnly"
                            autocomputed
                        >
                            <el-option v-for="(item, index) in patient.MemberList" :key="index" :label="item.name + ' (' + item.account + ')'" :value="item.pid" clearable filter> </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- 選負責人 -->
                <div class="flex-grow">
                    <div class="mb-1">
                        <span class="text-danger">* </span>
                        <span>{{ $t('crm.doctor') }}</span>
                    </div>
                    <div>
                        <el-select class="w-full" popper-class="cd-appt-popper" id="select_doc" v-model="sdid" filterable clearable placeholder="">
                            <el-option-group :label="$t('components.apptBtn.myself')">
                                <el-option :label="dname" :value="did" clearable filter> </el-option>
                            </el-option-group>
                            <el-option-group :label="$t('components.apptBtn.myContact')">
                                <el-option v-for="(item, index) in contactDocList" :key="index" :label="item.name" :value="item.did" clearable filter> </el-option>
                            </el-option-group>
                            <el-option-group :label="$t('components.apptBtn.whosDoctor', { partnerName: selectedPartnerName })">
                                <el-option v-for="(item, index) in partnerDocList" :key="index" :label="item.name" :value="item.did" clearable filter> </el-option>
                            </el-option-group>
                        </el-select>
                    </div>
                </div>
            </vs-col>
            <!-- 標題 -->
            <vs-col class="whitespace-no-wrap">
                <div class="mb-1">
                    <span class="text-danger">* </span>
                    <span>{{ $t('crm.caseTitle') }}</span>
                </div>
                <el-input class="w-full" v-model="title"></el-input>
            </vs-col>
            <!-- 服務標籤 -->
            <vs-col>
                <div class="mb-2">
                    <span>服務標籤</span>
                </div>
                <div class="flex flex-wrap gap-x-4 gap-y-2">
                    <vs-checkbox v-model="crm_tags" v-for="(item, index) in crm_tags_config" :key="index">{{ item }}</vs-checkbox>
                </div>
            </vs-col>
            <!-- 服務類型 -->
            <vs-col>
                <div class="mb-2">
                    <span>服務類型</span>
                </div>
                <div class="flex flex-wrap gap-x-4 gap-y-2">
                    <vs-checkbox v-model="crm_types" v-for="(item, index) in crm_types_config" :key="index">{{ item }}</vs-checkbox>
                </div>
            </vs-col>
            <!-- 註記 -->
            <vs-col class="cd-textarea">
                <div class="mb-1 flex justify-between">
                    <span>{{ $t('crm.notes') }}</span>
                    <importSetWord :dataType="'wd/all'" @importMsg="importMsg($event)"></importSetWord>
                </div>
                <vs-textarea rows="3" v-model="notes" />
            </vs-col>
            <!-- 追蹤或結案 radio btn -->
            <vs-col class="flex flex-wrap gap-4">
                <vs-radio v-model="days" vs-name="site" vs-value="1">{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 1 }) }}</vs-radio>
                <vs-radio v-model="days" vs-name="site" vs-value="3">{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 3 }) }}</vs-radio>
                <vs-radio v-model="days" vs-name="site" vs-value="5">{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 5 }) }}</vs-radio>
                <vs-radio v-model="days" vs-name="site" vs-value="7">{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 7 }) }}</vs-radio>
                <vs-radio v-model="days" vs-name="site" vs-value="30">{{ $t('crm.tracking') }} {{ $t('crm.days', { days: 30 }) }}</vs-radio>
                <vs-radio v-model="days" vs-name="site" vs-value="0">{{ $t('crm.finish') }}</vs-radio>
            </vs-col>
            <!-- 新增案件 按鈕 -->
            <vs-col class="space-x-4" vs-type="flex" vs-justify="flex-end">
                <vs-button class="cd-button-2w rounded" type="flat" @click="close">{{ $t('popup.cancel') }}</vs-button>
                <vs-button class="cd-button-4w rounded" @click="insertServiceNote">{{ $t('crm.addCase') }}</vs-button>
            </vs-col>
        </vs-row>
    </vs-popup>
</template>

<script>
import { insertServiceNote, fetchAuthList, fetchDoctorListByPartner, searchpartnermember, getCrmConfig } from '@/api/user'
import importSetWord from '@/components/importSetWord'
export default {
    components: {
        importSetWord,
    },
    props: {
        newCasePopupActive: {
            type: Boolean,
            default: false,
            required: true,
        },
        spid: {
            type: Number,
            default: null,
            required: false,
        },
        spname: {
            type: String,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            title: '',
            notes: '',
            days: 1,
            tags: [],
            patient: {
                pid: null,
                name: null,
                loadingmember: false,
                MemberList: [],
            },
            sdid: null,
            contactDocList: [],
            partnerDocList: [],
            selectedPartnerName: '',
            // caseTags: null,
            crm_tags_config: [],
            crm_types_config: [],
            crm_tags: [],
            crm_types: [],
        }
    },
    watch: {
        popupActive(n) {
            if (n === true && this.spid && this.spname) {
                this.patient = {
                    pid: null,
                    name: null,
                    loadingmember: false,
                    MemberList: [],
                }
                this.searchpartnermember(this.spname)
                this.patient.pid = this.spid
                this.patient.name = this.spname
            }
        },
        'patient.pid'(n) {
            if (!n) {
                this.patient.name = ''
                return
            }
            if (!this.patient.name) this.patient.name = _.find(this.patient.MemberList, { pid: n }).name
        },
    },
    computed: {
        popupActive: {
            get() {
                return this.newCasePopupActive
            },
            set(v) {
                return v
            },
        },
        partnerid() {
            return this.$store.getters.partner
        },
        partnerlist() {
            return JSON.parse(localStorage.getItem('partnerlist'))
        },
        did() {
            return this.$store.getters.did
        },
        dname() {
            return this.$store.getters.name
        },
    },
    mounted() {
        this.getCrmConfig()
        this.fetchAuthList()
        this.fetchDoctorListByPartner(this.partnerid)
        this.sdid = this.did
        document.getElementById('select').removeAttribute('readOnly')
    },
    methods: {
        // 新增服務案件
        insertServiceNote() {
            let tags = []
            var _self = this
            this.tags.forEach(function (v, i) {
                if (v === true) tags.push(_self.caseTags[i])
            })
            let payload = {
                partnerid: this.partnerid,
                title: this.title,
                notes: this.notes,
                spid: this.patient.pid,
                sdid: this.sdid,
                track: this.days,
                tags: tags,
            }
            insertServiceNote(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.$emit('fetchData')
                    this.close()
                    this.notify('grey', '新增成功')
                    this.title = ''
                    this.notes = ''
                    this.days = 1
                    this.sdid = this.did
                    this.patient.pid = ''
                    this.tags = []
                    this.$store.dispatch('fetchSRQuantity', { partnerid: this.partnerid })
                } else {
                    console.log('error message: ', res.data.msg)
                    this.notify('danger', this.$t('message.create_error') + this.$t('message.checkField'))
                }
            })
        },
        // 取醫師可授權的醫事人員名單
        fetchAuthList() {
            fetchAuthList(this.partnerid).then((res) => {
                this.myMedicalAllNurse = _.cloneDeep(res.data.data)
                this.tempMyMedicalAllNurse = _.cloneDeep(res.data.data)
            })
        },
        // 取這個合作夥伴裡可選擇的醫師名單
        fetchDoctorListByPartner(partnerid) {
            const selectedPartner = _.find(this.partnerlist, {
                id: partnerid.toString(),
            })
            this.selectedPartnerName = selectedPartner.company
            fetchDoctorListByPartner(partnerid).then((res) => {
                if (res.data.status == 'OK') {
                    this.contactDocList = res.data.contact
                    this.partnerDocList = res.data.partner
                }
            })
        },
        // 搜尋民眾
        searchpartnermember(query) {
            if (query != '') {
                this.patient.loadingmember = true
                setTimeout(() => {
                    searchpartnermember({ partnerid: this.partnerid, query: query }).then((res) => {
                        this.patient.MemberList = []
                        if (res.data.status == 'OK') {
                            this.patient.MemberList = res.data.items
                        }
                        this.patient.loadingmember = false
                    })
                }, 100)
            } else {
                this.patient.MemberList = []
            }
        },
        // 篩選符合民眾IOS Keyboard無法跳出 修復方法
        cancalReadOnly() {
            this.$nextTick(() => {
                const select = document.querySelector('#select')
                const select_doc = document.querySelector('#select_doc')
                const inputElement = this.$el.querySelector('.el-input__inner')
                const timer = setTimeout(() => {
                    select.removeAttribute('readonly')
                    select_doc.removeAttribute('readonly')
                    clearTimeout(timer)
                }, 200)
                if (inputElement) {
                    inputElement.removeAttribute('readonly')
                }
            })
        },
        removeReadOnly(className) {
            document.getElementById(className).removeAttribute('readOnly')
        },
        // 清空民眾欄位
        removesearchpartnermember() {
            this.patient.MemberList = []
        },
        // 取服務案件標籤
        async getCrmConfig() {
            const payload = { partner_id: this.partnerid }
            const res = await getCrmConfig(payload)
            this.crm_tags_config = res.data.data.crm_tags ? res.data.data.crm_tags : []
            this.crm_types_config = res.data.data.crm_types ? res.data.data.crm_types : []
        },
        // 匯入常用字句
        importMsg(msg) {
            // 判斷最後一個字元是不是換行符號
            let lastWord = this.notes.split('')[this.notes.split('').length - 1]
            if (lastWord != '\n' && lastWord != undefined) {
                this.notes += '\n'
            }
            this.notes += msg
        },
        // 關閉視窗
        close() {
            this.$emit('closePopup')
        },
        // 視窗通知
        notify(color, title, text = '') {
            this.$vs.notify({
                color: color,
                title: title,
                text: text,
                position: 'top-center',
            })
        },
    },
}
</script>
