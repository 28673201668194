<template>
    <div class="relative">
        <div class="text-center" v-if="!auth">
            <span>{{ $t('monitor.monitorNoAuth') }}</span
            ><br />
            <span class="cursor-pointer text-primary hover:font-semibold" @click="back">{{ $t('monitor.prev') }}</span>
        </div>
        <div class="text-center" v-else-if="!monitorList">
            <span>{{ $t('monitor.noMonitorGroup') }}</span
            ><br />
            <span class="cursor-pointer text-primary hover:font-semibold" @click="back">{{ $t('monitor.prev') }}</span>
        </div>
        <div v-else>
            <!-- header -->
            <div class="mb-2 flex justify-between">
                <h3>{{ $t('monitor.title') }}</h3>
                <div class="flex">
                    <vs-checkbox v-model="autoRefresh">{{ $t('monitor.autoRefresh') }}</vs-checkbox>
                    <div class="ml-2 flex items-center text-primary cursor-pointer hover:font-semibold" @click="getMonitorData(false)">
                        <feather-icon class="mx-1" icon="RefreshCwIcon" svgClasses="w-4 h-4"></feather-icon>
                        <span>{{ $t('monitor.refresh') }}</span>
                    </div>
                    <div class="ml-2 flex items-center text-primary cursor-pointer hover:font-semibold" v-if="windowWidth < 1024">
                        <feather-icon class="mx-1" icon="FilterIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span @click="switchFilter">{{ $t('apptMgmt.filter') }}</span>
                    </div>
                </div>
            </div>
            <div class="flex">
                <!-- filter -->
                <vs-sidebar class="cd-sidebar sm:w-auto w-full" :static-position="menuActive" :hidden-background="true" v-model="menuActive">
                    <div class="cd-filter">
                        <!-- 搜尋 姓名 身份證 手機 單位名稱 單位id -->
                        <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="mb-base w-full input-rounded-full" v-model="query" />
                        <!-- 選擇單位 -->
                        <el-select class="mb-base w-full" popper-class="cd-el-select" v-model="eid" :placeholder="$t('monitor.selectUnit')" @change="getMonitorData">
                            <el-option value="">{{ $t('monitor.all') }}</el-option>
                            <el-option v-for="item in monitorList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                        </el-select>
                        <!-- 篩選時間 -->
                        <div class="mb-base grid">
                            <vs-radio class="mb-3" v-model="time" vs-name="time" vs-value="0" @change="getMonitorData">{{ $t('monitor.allDays') }}</vs-radio>
                            <vs-radio class="mb-3" v-model="time" vs-name="time" vs-value="1440" @change="getMonitorData">{{ $t('monitor.oneDay') }}</vs-radio>
                            <vs-radio class="mb-3" v-model="time" vs-name="time" vs-value="4320" @change="getMonitorData">{{ $t('monitor.threeDays') }}</vs-radio>
                            <vs-radio class="mb-3" v-model="time" vs-name="time" vs-value="10080" @change="getMonitorData">{{ $t('monitor.sevenDays') }}</vs-radio>
                            <vs-radio class="mb-3" v-model="time" vs-name="time" vs-value="43200" @change="getMonitorData">{{ $t('monitor.thirtyDays') }}</vs-radio>
                        </div>
                        <!-- 篩選數據 -->
                        <div>
                            <div class="mb-3" v-for="(item, index) in config" :key="index">
                                <vs-checkbox v-model="checkType" :vs-value="item.mtype_id">{{ getMtypeName(item.mtype_id) }}</vs-checkbox>
                            </div>
                        </div>
                    </div>
                </vs-sidebar>
                <!-- content -->
                <div class="p-4 bg-white w-full" v-if="membersData && !menu">
                    <div v-for="(item, index) in membersData" :key="index">
                        <div class="mx-2 flex justify-between">
                            <!-- 姓名 性別 年齡 -->
                            <span class="cd-card-title whitespace-no-wrap cursor-pointer text-primary" @click="goToMr(item)">{{ item.name }} {{ getSex(item.sex) }} {{ getAge(item.birthday) }}</span>
                            <!-- CTA -->
                            <div class="flex justify-end items-center">
                                <div class="mr-2 flex items-center text-primary cursor-pointer hover:font-semibold" @click.stop="openNewCasePopup(item)">
                                    <feather-icon class="mx-1" icon="HeadphonesIcon" svgClasses="w-5 h-5"></feather-icon>
                                    <span class="whitespace-no-wrap">{{ $t('crm.addCase') }}</span>
                                </div>
                                <vs-dropdown class="cursor-pointer hover:font-semibold" vs-custom-content vs-trigger-click>
                                    <feather-icon class="mx-1" icon="MoreVerticalIcon" svgClasses="w-5 h-5"></feather-icon>
                                    <vs-dropdown-menu>
                                        <ul style="min-width: 9rem">
                                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.stop="openCallPopup(item)">
                                                <span>{{ $t('components.call.call') }}</span>
                                            </li>
                                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.stop="goToMr(item)">
                                                <span>{{ $t('apptMgmt.goToMr') }}</span>
                                            </li>
                                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.stop="showChatPopup(item.mid, item.name)">
                                                <span>{{ $t('apptMgmt.sendMsg') }}</span>
                                            </li>
                                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.stop="showApptPopup(0, item.pid, item.name)">
                                                <span>{{ $t('apptMgmt.agent') }}</span>
                                            </li>
                                            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.stop="showApptPopup(1, item.pid, item.name)">
                                                <span>{{ $t('apptMgmt.special') }}</span>
                                            </li>
                                        </ul>
                                    </vs-dropdown-menu>
                                </vs-dropdown>
                            </div>
                        </div>
                        <!-- 量測數據 -->
                        <div class="flex flex-wrap">
                            <div class="m-2 p-2 text-center w-32" style="border: #ccc 1px solid" v-for="data in item.data" :key="data.order" v-show="checkConfig(data.mtype_id)">
                                <span class="text-sm" style="color: #666">{{ getMtypeName(data.mtype_id) }}</span>
                                <br />
                                <div class="h-16" v-if="data.mtype_id == 7">
                                    <span v-if="data.image" class="cursor-pointer hover:font-semibold" style="font-size: 15px" :class="data.abnormal == 1 ? 'text-danger' : ''" @click="openImagePopup(data.image, data.mtype_id)">{{ data.value }}</span>
                                    <span v-else style="font-size: 15px" :class="data.abnormal == 1 ? 'text-danger' : ''">{{ data.value }}</span>
                                </div>
                                <div class="h-16" v-else>
                                    <span style="font-size: 1.6em" :class="data.abnormal == 1 ? 'text-danger' : ''">{{ data.value }}</span>
                                    <!-- 標準值顯示設定 -->
                                    <div v-show="data.has_value_map == 0" class="ml-2 whitespace-no-wrap cursor-pointer hover:font-semibold" @click="openDataRangeSettingPopup(item.pid, data.mtype_id, data.min, data.max)">
                                        <span class="text-sm" style="color: #666">{{ data.min }} ~ {{ data.max }}</span>
                                        <feather-icon class="text-primary" icon="Edit2Icon" svgClasses="w-3 h-3"></feather-icon>
                                    </div>
                                </div>
                                <span v-if="data.measure_date_time" class="text-sm whitespace-no-wrap" style="color: #666">{{ data.measure_date_time.slice(0, -3) }}</span>
                            </div>
                            <!-- 沒有數據 -->
                            <div class="m-2 w-32 flex items-center justify-center" style="border: #ccc 1px solid; height: 128px" v-if="!item.data || checkEmpty(item.data)">
                                <span style="color: #666">{{ $t('monitor.noHealthData') }}</span>
                            </div>
                        </div>
                        <vs-divider class="px-2 my-4" color="white" />
                    </div>
                </div>
                <!-- 沒資料 -->
                <div class="text-center" v-else-if="!menu">
                    <span class="flex justify-center mb-2">{{ $t('monitor.noData') }}</span>
                </div>
                <!-- 變更標準值視窗 -->
                <vs-popup :title="getMtypeName(stype)" style="z-index: 51001" :active.sync="popupActive" @close="close">
                    <div class="flex justify-around mb-base">
                        <vs-row>
                            <vs-col vs-lg="6" vs-sm="6" vs-xs="12">
                                <div>
                                    <span>{{ $t('monitor.min') }}</span>
                                    <vs-input v-model="min"></vs-input>
                                </div>
                            </vs-col>
                            <vs-col vs-lg="6" vs-sm="6" vs-xs="12">
                                <div>
                                    <span>{{ $t('monitor.max') }}</span>
                                    <vs-input v-model="max"></vs-input>
                                </div>
                            </vs-col>
                        </vs-row>
                    </div>
                    <div class="flex justify-end">
                        <vs-button class="mr-2" type="flat" @click="close">{{ $t('popup.cancel') }}</vs-button>
                        <vs-button @click="upsertDataRange">{{ $t('popup.confirm') }}</vs-button>
                    </div>
                </vs-popup>
                <vs-popup fullscreen :title="getMtypeName(stype)" style="z-index: 51001" :active.sync="ImagePopupActive" @close="close">
                    <div v-if="imageUrl">
                        <iframe :src="imageUrl" width="100%" style="overflow: hidden; height: 88vh; width: 100%" frameborder="0" id="pdfframe" ref="iframe"></iframe>
                    </div>
                </vs-popup>
                <!-- 訊息視窗 -->
                <send-chat :chatPopup="openChatPopup" :smid="parseInt(smid)" :sname="sname" @closeChat="closeChatPopup"></send-chat>
                <!-- 掛號視窗 -->
                <appt-btn :activePopup="openApptPopup" :patientData="patient" :mode="apptMode" @closeApptPopup="closeApptPopup"></appt-btn>
                <!-- 新增案件視窗 -->
                <add-case :spid="parseInt(crmPid)" :spname="crmPname" :newCasePopupActive="newCaseActive" @fetchData="goToCrm" @closePopup="newCaseClose"></add-case>
            </div>
        </div>
    </div>
</template>
<script>
import { getMonitorData, upsertDataRange } from '@/api/user'
import { debounce } from 'lodash'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import FeatherIcon from '../components/FeatherIcon.vue'
import sendChat from '@/components/sendChat'
import ApptBtn from '../components/apptBtn.vue'
import addCase from '@/components/addCase'

export default {
    components: {
        VuePerfectScrollbar,
        FeatherIcon,
        sendChat,
        ApptBtn,
        addCase,
    },
    data() {
        return {
            page: 1,
            popupActive: false,
            spid: null,
            stype: null,
            min: null,
            max: null,
            auth: null,
            monitorList: null,
            membersData: null,
            eid: null,
            page: 1,
            query: null,
            time: 1440,
            openChatPopup: false,
            openApptPopup: false,
            newCaseActive: false,
            smid: 0,
            sname: '',
            apptMode: 0,
            patient: {
                pid: null,
                name: null,
            },
            checkType: [2, 4, 5, 6, 7, 11, 12, 20, 21, 22, 33],
            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: 0.3,
            },
            crmPid: null,
            crmPname: null,
            fetchDataInterval: null,
            menu: false,
            ImagePopupActive: false,
            imageUrl: '',
            autoRefresh: false,
        }
    },
    watch: {
        query() {
            this.getMonitorData()
        },
        autoRefresh(v) {
            v ? this.openAutoRefresh() : this.closeAutoRefresh()
        },
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        heightStyle() {
            return window.innerHeight - 200
        },
        partnerid() {
            return this.$store.getters.partner
        },
        uiastaff() {
            return this.$store.getters.uiastaff
        },
        myProfile: {
            get() {
                return this.$store.getters.profile
            },
            set() {},
        },
        device() {
            return this.$store.getters.device
        },
        did() {
            return this.$store.getters.did
        },
        // 主選單開關
        menuActive: {
            get() {
                if (this.windowWidth >= 1024) {
                    return true
                } else {
                    return this.menu
                }
            },
            set(val) {},
        },
        config() {
            let data = [
                { mtype_id: 2, order: 3 },
                { mtype_id: 4, order: 4 },
                { mtype_id: 5, order: 1 },
                { mtype_id: 6, order: 2 },
                { mtype_id: 7, order: 11 },
                { mtype_id: 11, order: 8 },
                { mtype_id: 12, order: 9 },
                { mtype_id: 20, order: 5 },
                { mtype_id: 21, order: 6 },
                { mtype_id: 22, order: 7 },
                { mtype_id: 33, order: 10 },
            ]
            return data
        },
    },
    methods: {
        // 取會員資料
        getMonitorData: debounce(function (loading = true) {
            if (loading) this.$vs.loading()
            let payload = {
                partner_id: this.partnerid,
            }
            if (this.eid) payload['eids'] = this.eid
            if (this.page) payload['page'] = this.page
            if (this.query) payload['query'] = this.query
            if (this.time) payload['time'] = this.time
            getMonitorData(payload).then((res) => {
                if (res.data.status == 'OK') {
                    this.auth = res.data.auth
                    if (this.auth && res.data.data) {
                        this.monitorList = res.data.monitorList
                        this.membersData = _.cloneDeep(res.data.data.list)
                        // 做排序
                        if (this.membersData) {
                            this.membersData.forEach((v) => {
                                this.$vs.loading.close()
                                if (v.data) {
                                    v.data.forEach((v2) => {
                                        if (v2.mtype_id) v2.order = _.find(this.config, ['mtype_id', v2.mtype_id]).order
                                    })
                                    v.data = _.sortBy(v.data, 'order')
                                }
                            })
                        }
                    }
                    this.$vs.loading.close()
                }
            })
        }, 200),
        // 取性別
        getSex(val) {
            const statusMap = {
                0: '-',
                1: this.$t('monitor.man'),
                2: this.$t('monitor.woman'),
            }
            return statusMap[val]
        },
        // 取年齡
        getAge(val) {
            if (val === '0000-00-00' || !val) {
                return '--'
            } else {
                const ageMS = Date.parse(Date()) - Date.parse(val)
                const age = new Date()
                age.setTime(ageMS)
                const ageYear = age.getFullYear() - 1970
                return ageYear
            }
        },
        // 取數據名稱
        getMtypeName(val) {
            const statusMap = {
                2: this.$t('monitor.pulse_rate'),
                4: this.$t('monitor.blood_oxygen'),
                5: this.$t('monitor.temperature'),
                6: this.$t('monitor.weight'),
                7: this.$t('monitor.electrocardiography'),
                11: this.$t('monitor.systolic_blood_pressure'),
                12: this.$t('monitor.diastolic_blood_pressure'),
                20: this.$t('monitor.blood_sugar_before'),
                21: this.$t('monitor.blood_sugar_after'),
                22: this.$t('monitor.blood_sugar_empty'),
                33: this.$t('monitor.pulse_rate_bpm'),
            }
            return statusMap[val]
        },
        // 檢查是否要顯示該數據
        checkConfig(id) {
            let find = _.includes(this.checkType, id)
            return find
        },
        // 檢查數據有沒有被篩光
        checkEmpty(data) {
            let flag = true
            data.forEach((v) => {
                if (this.checkConfig(v.mtype_id)) flag = false
            })
            return flag
        },
        // 開啟該病人的病歷
        goToMr(data) {
            let patientJson = {
                name: data.name,
                pid: data.pid,
                account: data.account,
            }
            this.$store.commit('SET_ACTIVE_PATIENT_INFO', patientJson)
            let routerPayload = {
                path: '/emr2/' + data.pid + '/0?index=life',
            }
            this.$router.push(routerPayload)
        },
        // 開啟 傳送訊息視窗
        showChatPopup(mid, name) {
            document.body.style.overflow = 'hidden'
            this.smid = mid
            this.sname = name
            this.openChatPopup = true
        },
        // 關閉 傳送訊息視窗
        closeChatPopup(v) {
            document.body.style.overflow = 'visible'
            this.smid = 0
            this.openChatPopup = false
        },
        // 開啟 掛號視窗
        showApptPopup(index, pid = null, pname = null) {
            this.openApptPopup = true
            if (pid && pname) {
                this.patient.pid = parseInt(pid)
                this.patient.name = pname
            } else {
                this.patient = null
            }
            this.apptMode = index
        },
        // 關閉 掛號視窗
        closeApptPopup(v) {
            this.openApptPopup = false
        },
        // 開啟新增客服案件視窗
        openNewCasePopup(data) {
            const payload = {
                pid: data.pid,
                pname: data.name,
            }
            this.$bus.$emit('sn', payload)
            // this.crmPid = data.pid
            // this.crmPname = data.name
            // document.body.style.overflow = 'hidden'
            // this.newCaseActive = true
        },
        // 關閉新增客服案件視窗
        newCaseClose() {
            document.body.style.overflow = 'visible'
            this.newCaseActive = false
        },
        // 新增或修改 正常數值範圍
        upsertDataRange() {
            let payload = {
                partner_id: this.partnerid,
                spid: this.spid,
                mtype_id: this.stype,
                min: this.min,
                max: this.max,
            }
            upsertDataRange(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        var data = _.find(this.membersData, { pid: this.spid })
                        var data = _.find(data.data, { mtype_id: this.stype })
                        data.min = this.min
                        data.max = this.max
                        data.abnormal = Number(data.value) < Number(this.min) || Number(data.value) > Number(this.max) ? 1 : 0
                        this.$store.dispatch('notify', { title: '修改成功' })
                    } else {
                        console.log('error message: ', res.data.msg)
                        this.$store.dispatch('notify', { color: 'danger', title: '修改失敗' })
                    }
                })
                .then(() => {
                    this.spid = null
                    this.stype = null
                    this.min = null
                    this.max = null
                    this.close()
                })
        },
        // 開啟修改數值範圍視窗
        openDataRangeSettingPopup(pid, type, min, max) {
            this.spid = pid
            this.stype = type
            this.min = min
            this.max = max
            this.popupActive = true
            document.body.style.overflow = 'hidden'
        },
        // 關閉修改數值範圍視窗
        close() {
            this.popupActive = false
            document.body.style.overflow = 'visible'
        },
        // 取服務記錄 但是是空的 但是需要它
        fetchServiceRecord() {},
        // 跳轉至crm
        goToCrm() {
            this.$router.push('/crm')
        },
        // 回上一頁
        back() {
            window.history.back()
        },
        // 開關篩選sidebar (手機版)
        switchFilter() {
            this.menu = !this.menu
        },
        //開啟圖檔視窗
        openImagePopup(image, mtype) {
            this.stype = mtype
            this.imageUrl = process.env.VUE_APP_UPLOAD + '/electrocardiogram/' + image
            this.ImagePopupActive = true
        },
        // 開啟自動刷新 每分鐘自動刷新一次
        openAutoRefresh() {
            var _self = this
            _self.fetchDataInterval = setInterval(function () {
                _self.getMonitorData(false)
            }, 60000)
        },
        // 關閉自動刷新
        closeAutoRefresh() {
            clearInterval(this.fetchDataInterval)
        },
        // 開啟選擇通話對象視窗
        openCallPopup(data) {
            const payload = {
                title: '會員通話',
                pid: data.pid,
                noContact: true,
                noEnterprise: true,
            }
            this.$bus.$emit('callList', payload)
        },
    },
    mounted() {
        this.getMonitorData()
    },
    destroyed() {
        clearInterval(this.fetchDataInterval)
    },
    beforeRouteLeave(to, from, next) {
        this.openChatPopup = false
        this.openApptPopup = false
        this.newCaseActive = false
        next()
    },
}
</script>
